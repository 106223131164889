<template>
  <div>
    <el-form
      ref="nodeForm"
      :rules="rules"
      label-position="top"
      label-width="100px"
      :model="nodeToBind"
      class="node-details-form"
      v-loading="formLoading"
    >
      <el-form-item prop="node_name" :label="__('Name')">
        <el-input
          v-model="nodeToBind.node_name"
          :placeholder="__('payment node')"
        ></el-input>
      </el-form-item>

      <el-tabs
        v-model="activeTab"
        v-if="!formLoading"
        class="tabs"
        style="font-size: 1.2em;"
      >
        <el-tab-pane :label="__('Request')" name="request">
          <template v-loading="paymentProfilesLoading">
            <el-form-item
              :label="__('Payment profile')"
              prop="payment_node.data.request_json.payment_profile_id"
            >
              <el-select
                v-model="nodeToBind.payment_node.data.payment_profile_id"
                @change="paymentProfileChange"
                default-first-option
              >
                <el-option
                  v-for="paymentProfile in paymentProfiles"
                  :key="'_payment_profile_' + paymentProfile.payment_profile_id"
                  :value="paymentProfile.payment_profile_id"
                  :label="paymentProfile.payment_profile_name"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              prop="payment_node.data.request_json.payment_process_type"
              :label="__('Payment process type')"
            >
              <el-select
                v-model="
                  nodeToBind.payment_node.data.request_json.payment_process_type
                "
                @change="paymentProcessTypeChange"
                default-first-option
              >
                <el-option
                  v-for="paymentProcessType in paymentProcessTypes"
                  :key="paymentProcessType.key"
                  :label="paymentProcessType.label"
                  :value="paymentProcessType.key"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              v-for="param in requestRequiredParams"
              :key="'_request_required_param_' + param.key"
              :label="param.label"
              :required="true"
              :prop="
                'payment_node.data.request_json.required_params.' +
                  param.key +
                  '.data'
              "
            >
              <create-or-select
                :items="variablesExceptArrayVariables"
                label="variable_name"
                value="variable_id"
                :current_select="
                  variableSelected(param.key, 'request_json', 'required_params')
                "
                :placeholder="__('Select variable or type a new name')"
                :new-item-message="__('new variable')"
                style="width: 100%;"
                @change="
                  variableSelectionChange(
                    $event,
                    param.key,
                    'request_json',
                    'required_params'
                  )
                "
              />
              <!--              <div class="comments">Test data: {{ param.test_data }}</div>-->
            </el-form-item>

            <el-form-item
              v-for="param in requestOptionalParams"
              :key="'_request_optional_param_' + param.key"
              :label="param.label"
              :prop="param.key"
            >
              <create-or-select
                :items="variablesExceptArrayVariables"
                label="variable_name"
                value="variable_id"
                :current_select="
                  variableSelected(param.key, 'request_json', 'optional_params')
                "
                :placeholder="__('Select variable or type a new name')"
                :new-item-message="__('new variable')"
                style="width: 100%;"
                @change="
                  variableSelectionChange(
                    $event,
                    param.key,
                    'request_json',
                    'optional_params'
                  )
                "
              />
              <!--              <div class="comments">Test data: {{ param.test_data }}</div>-->
            </el-form-item>

            <el-form-item
              v-for="param in urlParams"
              :key="'_request_url_param_' + param.key"
              :label="param.label"
              :prop="
                'payment_node.data.request_json.url_params.' +
                  param.key +
                  '.data'
              "
            >
              <create-or-select
                :items="variablesExceptArrayVariables"
                label="variable_name"
                value="variable_id"
                :current_select="
                  variableSelected(param.key, 'request_json', 'url_params')
                "
                :placeholder="__('Select variable or type a new name')"
                :new-item-message="__('new variable')"
                style="width: 100%;"
                @change="
                  variableSelectionChange(
                    $event,
                    param.key,
                    'request_json',
                    'url_params'
                  )
                "
              />
              <!--              <div class="comments">Test data: {{ param.test_data }}</div>-->
            </el-form-item>
          </template>

          <el-form-item prop="custom_payload" :label="__('Custom payload')">
            <v-json-editor
              v-model="nodeToBind.payment_node.data.custom_payload"
              :options="editorOptions"
              @error="onEditorError"
              :plus="false"
              style="height: 100%"
            ></v-json-editor>
            <el-checkbox>{{ __("Overwrite default") }}</el-checkbox>
          </el-form-item>
        </el-tab-pane>

        <el-tab-pane :label="__('Response')" name="response">
          <el-form-item
            prop="payment_node.data.variable_rules"
            :label="__('Return Values')"
          >
            <el-table
              :data="responseParams"
              max-height="250"
              style="width: 100%"
            >
              <el-table-column
                prop="key"
                :label="__('Value')"
              ></el-table-column>
              <el-table-column :label="__('Assign the value to variable')">
                <template slot-scope="scope">
                  <create-or-select
                    :items="singleValuedAndSecureVariables"
                    label="variable_name"
                    value="variable_id"
                    :current_select="
                      variableSelected(scope.row.key, 'response_json', 'params')
                    "
                    :placeholder="__('Variable Name')"
                    :new-item-message="__('new variable')"
                    @change="
                      variableSelectionChange(
                        $event,
                        scope.row.key,
                        'response_json',
                        'params'
                      )
                    "
                  />
                </template>
              </el-table-column>
              <el-table-column>
                <template slot-scope="scope">
                  <div
                    v-if="
                      isNewVariable(scope.row.key, 'response_json', 'params')
                    "
                    class="new-variable-msg"
                  >
                    {{ __("new variable") }}
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </el-tab-pane>
      </el-tabs>
    </el-form>
  </div>
</template>

<script>
import BaseNode from "./BaseNode";
import _ from "lodash";
import { NODE_TYPES } from "@/constants/nodes";
import { mapActions, mapGetters, mapState } from "vuex";
import VJsonEditor from "v-jsoneditor";
import CreateOrSelect from "@/views/build/callflow/components/node-type-forms/components/CreateOrSelect";

export default {
  mixins: [BaseNode],
  components: {
    VJsonEditor,
    CreateOrSelect
  },
  data() {
    return {
      rules: {
        payment_node: {
          data: {
            payment_profile_id: {
              required: true,
              message: __("Please select a payment profile"),
              trigger: "blur"
            },
            request_json: {
              payment_process_type: {
                required: true,
                message: __("Please select a payment process type"),
                trigger: "blur"
              },
              required_params: {},
              url_params: {}
            }
          }
        }
      },
      paymentProcessTypes: [],
      editorOptions: {
        mainMenuBar: false,
        mode: "code"
      },
      activeTab: "request",
      returnValue: [],
      returnValueComponentKey: 0,
      selectedPaymentProcessType: {},
      selectedPaymentProfile: {},
      selectedPaymentGateway: {},
      formLoading: false,
      requiredParamsRefreshkey: 0,
      optionalParamsRefreshkey: 0,
      urlParamsRefreshkey: 0
    };
  },
  computed: {
    ...mapState("paymentprofiles", {
      paymentProfiles: state => state.paymentProfiles,
      paymentProfilesLoading: state => state.loading
    }),

    ...mapGetters("paymentprofiles", {
      getPaymentProfile: "getPaymentProfile"
    }),

    ...mapState("paymentgateways", {
      paymentGatewaysLoading: state => state.loading
    }),

    ...mapGetters("paymentgateways", {
      getPaymentGateway: "getPaymentGateway"
    }),

    ...mapState("variables", {
      variableLoading: state => state.loading
    }),

    // ...mapGetters("variables", {
    //   variablesExceptArrayVariables: "variablesExceptArrayVariables"
    // }),

    /**
     * method to do extra checks to validate form, it cannot be handled by the element UI form validations
     * @returns {boolean}
     */
    formHasErrors() {
      return false;
    },

    requestRequiredParams() {
      if (
        this.requiredParamsRefreshkey >= 0 &&
        this.nodeToBind.payment_node.data.request_json.required_params
      ) {
        let allRequiredRequestParams = Object.values(
          this.nodeToBind.payment_node.data.request_json.required_params
        );
        return allRequiredRequestParams.filter(param => {
          return (
            Object.prototype.hasOwnProperty.call(param, "type") &&
            // param.hasOwnProperty("type") &&
            param.type.startsWith("payment_node:")
          );
        });
      } else {
        return [];
      }
    },

    requestOptionalParams() {
      if (
        this.optionalParamsRefreshkey >= 0 &&
        this.nodeToBind.payment_node.data.request_json.optional_params
      ) {
        let allOptionalRequestParams = Object.values(
          this.nodeToBind.payment_node.data.request_json.optional_params
        );
        return allOptionalRequestParams.filter(param => {
          return (
            param &&
            Object.prototype.hasOwnProperty.call(param, "type") &&
            param.type.startsWith("payment_node:")
          );
        });
      } else {
        return [];
      }
    },

    urlParams() {
      if (
        this.urlParamsRefreshkey >= 0 &&
        this.nodeToBind.payment_node.data.request_json.url_params
      ) {
        let allUrlParams = Object.values(
          this.nodeToBind.payment_node.data.request_json.url_params
        );
        return allUrlParams.filter(param => {
          return (
            this.requestRequiredParams.filter(function(requestParam) {
              return requestParam.key == param.key;
            }).length == 0 &&
            Object.prototype.hasOwnProperty.call(param, "type") &&
            param.type.startsWith("payment_node:")
          );
        });
      } else {
        return [];
      }
    },

    responseParams() {
      if (this.selectedPaymentProcessType.response_params) {
        return this.selectedPaymentProcessType.response_params.map(param => ({
          key: param
        }));
      } else {
        return [];
      }
    }
  },
  methods: {
    ...mapActions("paymentprofiles", {
      getPaymentProfiles: "getPaymentProfiles",
      testPaymentProfile: "testPaymentProfile",
      generateRequestBody: "generateRequestBody"
    }),

    ...mapActions("paymentgateways", {
      getPaymentGateways: "getPaymentGateways"
    }),

    onEditorError(e) {
      console.log(e);
    },

    handleUpdateReturnValue(value) {
      this.returnValue = [...value];
    },

    // new
    initializePaymentProcessNode() {
      this.formLoading = true;
      Promise.all([this.getPaymentGateways(), this.getPaymentProfiles()])
        .then(() => {
          if (
            !this.nodeToBind.node_id ||
            _.isEmpty(this.nodeToBind.payment_node)
          ) {
            //console.log(JSON.stringify(this.nodeToBind));
            this.$set(this.nodeToBind, "payment_node", {});
            this.$set(this.nodeToBind.payment_node, "data", {});
            this.$set(
              this.nodeToBind.payment_node.data,
              "payment_profile_id",
              null
            );
            this.initializePaymentProfileData();
            this.$set(
              this.nodeToBind,
              "node_type",
              NODE_TYPES.PAYMENT.NODE_TYPE
            );
          } else {
            if (
              !_.has(this.nodeToBind, "payment_node.data.payment_profile_id") ||
              _.isNull(this.nodeToBind.payment_node.data.payment_profile_id)
            ) {
              this.initializePaymentProfileData();
            } else {
              this.selectedPaymentProfile = this.getPaymentProfile(
                this.nodeToBind.payment_node.data.payment_profile_id
              );
              this.selectedPaymentGateway = this.getPaymentGateway(
                this.selectedPaymentProfile.payment_gateway_id
              );

              if (
                !_.has(
                  this.nodeToBind,
                  "payment_node.data.request_json.payment_process_type"
                )
              ) {
                this.$set(
                  this.nodeToBind.payment_node.data.request_json,
                  "payment_process_type",
                  null
                );
              }

              if (
                !_.has(
                  this.nodeToBind,
                  "payment_node.data.request_json.required_params"
                ) ||
                _.isEmpty(
                  this.nodeToBind.payment_node.data.request_json.required_params
                )
              ) {
                this.$set(
                  this.nodeToBind.payment_node.data.request_json,
                  "required_params",
                  {}
                );
              }
              if (
                !_.has(
                  this.nodeToBind,
                  "payment_node.data.request_json.optional_params"
                ) ||
                _.isEmpty(
                  this.nodeToBind.payment_node.data.request_json.optional_params
                )
              ) {
                this.$set(
                  this.nodeToBind.payment_node.data.request_json,
                  "optional_params",
                  {}
                );
              }

              if (
                !_.has(
                  this.nodeToBind,
                  "payment_node.data.request_json.url_params"
                ) ||
                _.isEmpty(
                  this.nodeToBind.payment_node.data.request_json.url_params
                )
              ) {
                this.$set(
                  this.nodeToBind.payment_node.data.request_json,
                  "url_params",
                  {}
                );
              }

              if (
                !_.has(
                  this.nodeToBind,
                  "payment_node.data.response_json.params"
                ) ||
                _.isEmpty(
                  this.nodeToBind.payment_node.data.response_json.params
                )
              ) {
                this.$set(
                  this.nodeToBind.payment_node.data.response_json,
                  "params",
                  {}
                );
              }

              this.paymentProcessTypes.splice(
                0,
                this.paymentProcessTypes.length
              );

              if (
                this.selectedPaymentGateway &&
                this.selectedPaymentGateway.json_data &&
                this.selectedPaymentGateway.json_data.payment_process_types
              ) {
                this.paymentProcessTypes = Object.values(
                  this.selectedPaymentGateway.json_data.payment_process_types
                );
                this.selectedPaymentProcessType = this.paymentProcessTypes.find(
                  item =>
                    item.key ===
                    this.nodeToBind.payment_node.data.request_json
                      .payment_process_type
                );

                if (this.selectedPaymentProcessType) {
                  if (this.selectedPaymentProcessType.request_params) {
                    if (
                      this.selectedPaymentProcessType.request_params.required &&
                      this.selectedPaymentProcessType.request_params.required
                        .length
                    ) {
                      const existingRequiredRequestParams = Object.keys(
                        _.cloneDeep(
                          this.nodeToBind.payment_node.data.request_json
                            .required_params
                        )
                      );

                      for (const paramKey of this.selectedPaymentProcessType
                        .request_params.required) {
                        if (!existingRequiredRequestParams.includes(paramKey)) {
                          const param = _.get(this.selectedPaymentGateway, [
                            "json_data",
                            "request_parameters",
                            paramKey
                          ]);
                          this.$set(
                            this.nodeToBind.payment_node.data.request_json
                              .required_params,
                            paramKey,
                            param
                          );

                          this.$set(
                            this.rules.payment_node.data.request_json
                              .required_params,
                            paramKey,
                            {}
                          );
                          this.$set(
                            this.rules.payment_node.data.request_json
                              .required_params[paramKey],
                            "data",
                            {
                              required: true,
                              message: param.label + " is required",
                              trigger: "change"
                            }
                          );
                          this.requiredParamsRefreshkey++;
                        }
                      }
                    }

                    if (
                      this.selectedPaymentProcessType.request_params.optional &&
                      this.selectedPaymentProcessType.request_params.optional
                        .length
                    ) {
                      const existingOptionalRequestParams = Object.keys(
                        _.cloneDeep(
                          this.nodeToBind.payment_node.data.request_json
                            .optional_params
                        )
                      );
                      for (const paramKey of this.selectedPaymentProcessType
                        .request_params.optional) {
                        if (!existingOptionalRequestParams.includes(paramKey)) {
                          const param = _.get(this.selectedPaymentGateway, [
                            "json_data",
                            "request_parameters",
                            paramKey
                          ]);
                          this.$set(
                            this.nodeToBind.payment_node.data.request_json
                              .optional_params,
                            paramKey,
                            param
                          );
                        }
                      }
                      this.optionalParamsRefreshkey++;
                    }
                  }

                  if (
                    this.selectedPaymentProcessType.url_params &&
                    this.selectedPaymentProcessType.url_params.length
                  ) {
                    let existingUrlParams = Object.keys(
                      _.cloneDeep(
                        this.nodeToBind.payment_node.data.request_json
                          .url_params
                      )
                    );
                    for (const paramKey of this.selectedPaymentProcessType
                      .url_params) {
                      if (!existingUrlParams.includes(paramKey)) {
                        const param = _.get(this.selectedPaymentProfile, [
                          "json_payload",
                          "request_parameters",
                          paramKey
                        ]);
                        this.$set(
                          this.nodeToBind.payment_node.data.request_json
                            .url_params,
                          paramKey,
                          param
                        );
                        this.$set(
                          this.rules.payment_node.data.request_json.url_params,
                          paramKey,
                          {}
                        );
                        this.$set(
                          this.rules.payment_node.data.request_json.url_params[
                            paramKey
                          ],
                          "data",
                          {
                            required: true,
                            message: __(":label is required", {
                              label: param.label
                            }),
                            trigger: "change"
                          }
                        );
                      }
                    }
                    this.urlParamsRefreshkey++;
                  }

                  if (
                    this.selectedPaymentProcessType.response_params &&
                    this.selectedPaymentProcessType.response_params.length
                  ) {
                    let existingResponseParams = Object.keys(
                      _.cloneDeep(
                        this.nodeToBind.payment_node.data.response_json.params
                      )
                    );
                    for (const paramKey of this.selectedPaymentProcessType
                      .response_params) {
                      if (!existingResponseParams.includes(paramKey)) {
                        const param = _.get(this.selectedPaymentGateway, [
                          "json_data",
                          "response_parameters",
                          paramKey
                        ]);
                        this.$set(
                          this.nodeToBind.payment_node.data.response_json
                            .params,
                          paramKey,
                          param
                        );
                      }
                    }
                  }
                }
              }
            }
          }
        })
        .catch(err => {
          this.$message({
            type: "error",
            message: err.message || "operation failed"
          });
        })
        .finally(() => {
          this.formLoading = false;
        });
    },

    initializePaymentProfileData() {
      this.$set(this.nodeToBind.payment_node.data, "request_json", {});
      this.$set(
        this.nodeToBind.payment_node.data.request_json,
        "payment_process_type",
        null
      );
      this.$set(
        this.nodeToBind.payment_node.data.request_json,
        "required_params",
        {}
      );
      this.$set(
        this.nodeToBind.payment_node.data.request_json,
        "optional_params",
        {}
      );
      this.$set(
        this.nodeToBind.payment_node.data.request_json,
        "url_params",
        {}
      );
      this.$set(this.nodeToBind.payment_node.data, "custom_payload", null);
      this.$set(this.nodeToBind.payment_node.data, "response_json", {});
      this.$set(this.nodeToBind.payment_node.data.response_json, "params", {});
    },

    paymentProfileChange(paymentProfileId) {
      this.selectedPaymentProfile = _.cloneDeep(
        this.getPaymentProfile(paymentProfileId)
      );
      this.selectedPaymentGateway = _.cloneDeep(
        this.getPaymentGateway(this.selectedPaymentProfile.payment_gateway_id)
      );

      this.paymentProcessTypes.splice(0, this.paymentProcessTypes.length);
      this.$set(this.nodeToBind.payment_node.data, "request_json", {});
      this.$set(
        this.nodeToBind.payment_node.data.request_json,
        "payment_process_type",
        null
      );
      this.$set(
        this.nodeToBind.payment_node.data.request_json,
        "required_params",
        {}
      );
      this.$set(
        this.nodeToBind.payment_node.data.request_json,
        "optional_params",
        {}
      );
      this.$set(
        this.nodeToBind.payment_node.data.request_json,
        "url_params",
        {}
      );
      this.$set(this.nodeToBind.payment_node.data.response_json, "params", {});

      if (
        this.selectedPaymentGateway &&
        this.selectedPaymentGateway.json_data &&
        this.selectedPaymentGateway.json_data.payment_process_types
      ) {
        for (let i in this.selectedPaymentGateway.json_data
          .payment_process_types) {
          this.paymentProcessTypes.push(
            this.selectedPaymentGateway.json_data.payment_process_types[i]
          );
        }
      }
    },

    paymentProcessTypeChange(paymentProcessType) {
      if (paymentProcessType !== null) {
        // this.selectedPaymentProfile = _.cloneDeep(
        //   this.getPaymentProfile(
        //     this.nodeToBind.payment_node.data.payment_profile_id
        //   )
        // );
        this.selectedPaymentProcessType = this.paymentProcessTypes.find(
          item => item.key === paymentProcessType
        );
        // let this.selectedPaymentProcessType = this.paymentProcessTypes.find(item => item.key === paymentProcessType);

        this.$set(
          this.nodeToBind.payment_node.data.request_json,
          "payment_process_type",
          paymentProcessType
        );
        this.$set(
          this.nodeToBind.payment_node.data.request_json,
          "required_params",
          {}
        );
        this.$set(
          this.nodeToBind.payment_node.data.request_json,
          "optional_params",
          {}
        );
        this.$set(
          this.nodeToBind.payment_node.data.request_json,
          "url_params",
          {}
        );
        this.$set(
          this.nodeToBind.payment_node.data.response_json,
          "params",
          {}
        );

        if (
          this.selectedPaymentProcessType &&
          this.selectedPaymentGateway &&
          this.selectedPaymentGateway.json_data
        ) {
          if (this.selectedPaymentGateway.json_data.request_parameters) {
            if (this.selectedPaymentProcessType.request_params) {
              if (
                this.selectedPaymentProcessType.request_params.required &&
                this.selectedPaymentProcessType.request_params.required.length
              ) {
                for (let i in this.selectedPaymentProcessType.request_params
                  .required) {
                  let paramKey = this.selectedPaymentProcessType.request_params
                    .required[i];
                  let paymentGatewayParam = this.selectedPaymentGateway
                    .json_data.request_parameters[paramKey];
                  if (paymentGatewayParam.type.startsWith("payment_node:")) {
                    this.$set(
                      this.nodeToBind.payment_node.data.request_json
                        .required_params,
                      paramKey,
                      paymentGatewayParam
                    );

                    this.$set(
                      this.rules.payment_node.data.request_json.required_params,
                      paramKey,
                      {}
                    );
                    this.$set(
                      this.rules.payment_node.data.request_json.required_params[
                        paramKey
                      ],
                      "data",
                      {
                        required: true,
                        message: paymentGatewayParam.label + " is required",
                        trigger: "change"
                      }
                    );
                  }
                }
              }

              if (
                this.selectedPaymentProcessType.request_params.optional &&
                this.selectedPaymentProcessType.request_params.optional.length
              ) {
                for (let i in this.selectedPaymentProcessType.request_params
                  .optional) {
                  let paramKey = this.selectedPaymentProcessType.request_params
                    .optional[i];
                  let paymentGatewayParam = this.selectedPaymentGateway
                    .json_data.request_parameters[paramKey];
                  if (paymentGatewayParam.type.startsWith("payment_node:")) {
                    this.$set(
                      this.nodeToBind.payment_node.data.request_json
                        .optional_params,
                      paramKey,
                      paymentGatewayParam
                    );
                  }
                }
              }
            }
            if (
              this.selectedPaymentProcessType.url_params &&
              this.selectedPaymentProcessType.url_params.length
            ) {
              for (let i in this.selectedPaymentProcessType.url_params) {
                let paramKey = this.selectedPaymentProcessType.url_params[i];
                let paymentGatewayParam = this.selectedPaymentGateway.json_data
                  .request_parameters[paramKey];
                if (paymentGatewayParam.type.startsWith("payment_node:")) {
                  this.$set(
                    this.nodeToBind.payment_node.data.request_json.url_params,
                    paramKey,
                    paymentGatewayParam
                  );
                  this.$set(
                    this.rules.payment_node.data.request_json.url_params,
                    paramKey,
                    {}
                  );
                  this.$set(
                    this.rules.payment_node.data.request_json.url_params[
                      paramKey
                    ],
                    "data",
                    {
                      required: true,
                      message: __(":label is required", {
                        label: paymentGatewayParam.label
                      }),
                      trigger: "change"
                    }
                  );
                }
              }
            }
          }

          let responseParameters = this.selectedPaymentGateway.json_data
            .response_parameters;
          if (responseParameters) {
            for (let i in responseParameters) {
              this.$set(
                this.nodeToBind.payment_node.data.response_json.params,
                responseParameters[i].key,
                responseParameters[i]
              );
            }
          }
        }

        if (
          this.selectedPaymentProfile &&
          this.selectedPaymentProfile.json_payload
        ) {
          if (
            this.selectedPaymentProfile.json_payload
              .additional_request_parameters
          ) {
            for (let i in this.selectedPaymentProfile.json_payload
              .additional_request_parameters) {
              const additionalRequestParam = _.cloneDeep(
                this.selectedPaymentProfile.json_payload
                  .additional_request_parameters[i]
              );
              if (additionalRequestParam.type.startsWith("payment_node:")) {
                if (
                  additionalRequestParam.payment_process_types &&
                  additionalRequestParam.payment_process_types[
                    this.selectedPaymentProcessType.key
                  ]
                ) {
                  if (
                    additionalRequestParam.payment_process_types[
                      this.selectedPaymentProcessType.key
                    ] === "required"
                  ) {
                    this.$set(
                      this.nodeToBind.payment_node.data.request_json
                        .required_params,
                      additionalRequestParam.key,
                      additionalRequestParam
                    );
                    this.$set(
                      this.rules.payment_node.data.request_json.required_params,
                      additionalRequestParam.key,
                      {}
                    );
                    this.$set(
                      this.rules.payment_node.data.request_json.required_params[
                        additionalRequestParam.key
                      ],
                      "data",
                      {
                        required: true,
                        message: additionalRequestParam.label + " is required",
                        trigger: "change"
                      }
                    );
                  } else if (
                    additionalRequestParam.payment_process_types[
                      this.selectedPaymentProcessType.key
                    ] === "optional"
                  ) {
                    this.$set(
                      this.nodeToBind.payment_node.data.request_json
                        .optional_params,
                      additionalRequestParam.key,
                      additionalRequestParam
                    );
                  }
                }
              }
            }
          }

          if (
            this.selectedPaymentProfile.json_payload
              .additional_response_parameters
          ) {
            for (let i in this.selectedPaymentProfile.json_payload
              .additional_response_parameters) {
              const additionalResponseParam = _.cloneDeep(
                this.selectedPaymentProfile.json_payload
                  .additional_response_parameters[i]
              );
              this.$set(
                this.nodeToBind.payment_node.data.response_json.params,
                additionalResponseParam.key,
                additionalResponseParam
              );
            }
          }
        }
      } else {
        this.$set(this.nodeToBind.payment_node.data, "request_json", {});
        this.$set(
          this.nodeToBind.payment_node.data.request_json,
          "payment_process_type",
          null
        );
        this.$set(
          this.nodeToBind.payment_node.data.request_json,
          "required_params",
          {}
        );
        this.$set(
          this.nodeToBind.payment_node.data.request_json,
          "optional_params",
          {}
        );
        this.$set(
          this.nodeToBind.payment_node.data.request_json,
          "url_params",
          {}
        );
        this.$set(
          this.nodeToBind.payment_node.data.response_json,
          "params",
          {}
        );
      }
    },

    variableSelected(
      var_name,
      json_type = "request_json",
      param_type = "required_params"
    ) {
      if (
        _.has(this.nodeToBind, [
          "payment_node",
          "data",
          json_type,
          param_type,
          var_name,
          "data"
        ])
      ) {
        let var_object = this.nodeToBind.payment_node.data[json_type][
          param_type
        ][var_name].data;
        const { variable_id, variable_name } = var_object;
        return variable_id === -1 ? variable_name : variable_id;
      } else {
        return "";
      }
    },

    variableSelectionChange(
      option,
      var_name,
      json_type = "request_json",
      param_type = "required_params"
    ) {
      this.newVariableCreated = this.newVariableCreated || option.value === -1;
      let var_object = {
        variable_id: option.value,
        variable_name: option.label
      };
      if (_.isEmpty(var_object.variable_name)) {
        var_object.variable_id = "";
      }

      if (
        !_.has(this.nodeToBind, [
          "payment_node",
          "data",
          json_type,
          param_type,
          var_name
        ])
      ) {
        this.$set(
          this.nodeToBind.payment_node.data[json_type][param_type],
          var_name,
          {}
        );
        let param = {};
        if (json_type === "request_json") {
          param = _.get(this.selectedPaymentProfile, [
            "json_payload",
            "request_parameters",
            var_name
          ]);
        } else {
          param = _.get(this.selectedPaymentProfile, [
            "json_payload",
            "response_parameters",
            var_name
          ]);
        }
        this.$set(
          this.nodeToBind.payment_node.data[json_type][param_type],
          var_name,
          param
        );
      }
      this.$set(
        this.nodeToBind.payment_node.data[json_type][param_type][var_name],
        "data",
        var_object
      );
    },

    cleanUpNode() {
      if (!this.formHasErrors) {
        this.nodeToBind = this.cleanUpNodeToPrepareForSubmit();
        this.createOrEditNode();
      } else {
        this.toggleNodeSubmit(false);
      }
    },

    isNewVariable(var_name, json_type, param_type) {
      let var_id = _.get(
        this.nodeToBind.payment_node.data[json_type][param_type][var_name],
        "data.variable_id"
      );
      return var_id === -1;
    }
  },
  created() {
    this.initializePaymentProcessNode();
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/node-palette.scss";
@import "~@/styles/typography.scss";
@import "~@/styles/node_common.scss";
@import "~@/styles/element-variables.scss";

.el-form-item:last-of-type {
  margin-bottom: 10px;
}

.text-input ::v-deep textarea {
  resize: none;
}

.el-select {
  width: 100%;
}

.new-variable-msg {
  font-size: 0.75rem;
  color: $--color-success;
}
</style>
