var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-form',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.formLoading),expression:"formLoading"}],ref:"nodeForm",staticClass:"node-details-form",attrs:{"rules":_vm.rules,"label-position":"top","label-width":"100px","model":_vm.nodeToBind}},[_c('el-form-item',{attrs:{"prop":"node_name","label":_vm.__('Name')}},[_c('el-input',{attrs:{"placeholder":_vm.__('payment node')},model:{value:(_vm.nodeToBind.node_name),callback:function ($$v) {_vm.$set(_vm.nodeToBind, "node_name", $$v)},expression:"nodeToBind.node_name"}})],1),(!_vm.formLoading)?_c('el-tabs',{staticClass:"tabs",staticStyle:{"font-size":"1.2em"},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('el-tab-pane',{attrs:{"label":_vm.__('Request'),"name":"request"}},[[_c('el-form-item',{attrs:{"label":_vm.__('Payment profile'),"prop":"payment_node.data.request_json.payment_profile_id"}},[_c('el-select',{attrs:{"default-first-option":""},on:{"change":_vm.paymentProfileChange},model:{value:(_vm.nodeToBind.payment_node.data.payment_profile_id),callback:function ($$v) {_vm.$set(_vm.nodeToBind.payment_node.data, "payment_profile_id", $$v)},expression:"nodeToBind.payment_node.data.payment_profile_id"}},_vm._l((_vm.paymentProfiles),function(paymentProfile){return _c('el-option',{key:'_payment_profile_' + paymentProfile.payment_profile_id,attrs:{"value":paymentProfile.payment_profile_id,"label":paymentProfile.payment_profile_name}})}),1)],1),_c('el-form-item',{attrs:{"prop":"payment_node.data.request_json.payment_process_type","label":_vm.__('Payment process type')}},[_c('el-select',{attrs:{"default-first-option":""},on:{"change":_vm.paymentProcessTypeChange},model:{value:(
                _vm.nodeToBind.payment_node.data.request_json.payment_process_type
              ),callback:function ($$v) {_vm.$set(_vm.nodeToBind.payment_node.data.request_json, "payment_process_type", $$v)},expression:"\n                nodeToBind.payment_node.data.request_json.payment_process_type\n              "}},_vm._l((_vm.paymentProcessTypes),function(paymentProcessType){return _c('el-option',{key:paymentProcessType.key,attrs:{"label":paymentProcessType.label,"value":paymentProcessType.key}})}),1)],1),_vm._l((_vm.requestRequiredParams),function(param){return _c('el-form-item',{key:'_request_required_param_' + param.key,attrs:{"label":param.label,"required":true,"prop":'payment_node.data.request_json.required_params.' +
                param.key +
                '.data'}},[_c('create-or-select',{staticStyle:{"width":"100%"},attrs:{"items":_vm.variablesExceptArrayVariables,"label":"variable_name","value":"variable_id","current_select":_vm.variableSelected(param.key, 'request_json', 'required_params'),"placeholder":_vm.__('Select variable or type a new name'),"new-item-message":_vm.__('new variable')},on:{"change":function($event){return _vm.variableSelectionChange(
                  $event,
                  param.key,
                  'request_json',
                  'required_params'
                )}}})],1)}),_vm._l((_vm.requestOptionalParams),function(param){return _c('el-form-item',{key:'_request_optional_param_' + param.key,attrs:{"label":param.label,"prop":param.key}},[_c('create-or-select',{staticStyle:{"width":"100%"},attrs:{"items":_vm.variablesExceptArrayVariables,"label":"variable_name","value":"variable_id","current_select":_vm.variableSelected(param.key, 'request_json', 'optional_params'),"placeholder":_vm.__('Select variable or type a new name'),"new-item-message":_vm.__('new variable')},on:{"change":function($event){return _vm.variableSelectionChange(
                  $event,
                  param.key,
                  'request_json',
                  'optional_params'
                )}}})],1)}),_vm._l((_vm.urlParams),function(param){return _c('el-form-item',{key:'_request_url_param_' + param.key,attrs:{"label":param.label,"prop":'payment_node.data.request_json.url_params.' +
                param.key +
                '.data'}},[_c('create-or-select',{staticStyle:{"width":"100%"},attrs:{"items":_vm.variablesExceptArrayVariables,"label":"variable_name","value":"variable_id","current_select":_vm.variableSelected(param.key, 'request_json', 'url_params'),"placeholder":_vm.__('Select variable or type a new name'),"new-item-message":_vm.__('new variable')},on:{"change":function($event){return _vm.variableSelectionChange(
                  $event,
                  param.key,
                  'request_json',
                  'url_params'
                )}}})],1)})],_c('el-form-item',{attrs:{"prop":"custom_payload","label":_vm.__('Custom payload')}},[_c('v-json-editor',{staticStyle:{"height":"100%"},attrs:{"options":_vm.editorOptions,"plus":false},on:{"error":_vm.onEditorError},model:{value:(_vm.nodeToBind.payment_node.data.custom_payload),callback:function ($$v) {_vm.$set(_vm.nodeToBind.payment_node.data, "custom_payload", $$v)},expression:"nodeToBind.payment_node.data.custom_payload"}}),_c('el-checkbox',[_vm._v(_vm._s(_vm.__("Overwrite default")))])],1)],2),_c('el-tab-pane',{attrs:{"label":_vm.__('Response'),"name":"response"}},[_c('el-form-item',{attrs:{"prop":"payment_node.data.variable_rules","label":_vm.__('Return Values')}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.responseParams,"max-height":"250"}},[_c('el-table-column',{attrs:{"prop":"key","label":_vm.__('Value')}}),_c('el-table-column',{attrs:{"label":_vm.__('Assign the value to variable')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('create-or-select',{attrs:{"items":_vm.singleValuedAndSecureVariables,"label":"variable_name","value":"variable_id","current_select":_vm.variableSelected(scope.row.key, 'response_json', 'params'),"placeholder":_vm.__('Variable Name'),"new-item-message":_vm.__('new variable')},on:{"change":function($event){return _vm.variableSelectionChange(
                      $event,
                      scope.row.key,
                      'response_json',
                      'params'
                    )}}})]}}],null,false,2236570140)}),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                    _vm.isNewVariable(scope.row.key, 'response_json', 'params')
                  )?_c('div',{staticClass:"new-variable-msg"},[_vm._v(" "+_vm._s(_vm.__("new variable"))+" ")]):_vm._e()]}}],null,false,529258846)})],1)],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }